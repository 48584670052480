import store from '@/store'

export const guardLogin = (to, from, next) => {
  if (!localStorage.getItem('token')) {
    next({ name: 'Login' })
  } else {
    next()
  }
}

export const guardGuest = (to, from, next) => {
  if (localStorage.getItem('token')) {
    next({ name: 'Home' })
  } else {
    next()
  }
}

export const beforeEach = async (to, from, next) => {
  if (store.getters.loading) {
    await store.dispatch('initApp')
    next()
  } else {
    next()
  }
}
