import Service from '@/services'
import * as types from './mutation-types'
export const loading = ({ commit }) => {
  commit(types.LOADING)
}

export const loaded = ({ commit }) => {
  commit(types.LOADED)
}

export const logout = async function ({ commit }) {
  try {
    if (localStorage.getItem('token')) { await Service.post('/Authentication/Logout', {}) }
  } catch (e) {
  } finally {
    commit(types.LOGOUT, true)
  }
}

export const fetchUserData = async ({ commit, state, dispatch, getters }) => {
  try {
    const result = await Service.get(
      'Frontend/GetUserCommonData'
    )

    commit(types.SET_USER_COMMON_DATA, result.data)
  } catch (e) {
    await dispatch('logout', true)
  }
}

export const initApp = async ({ commit, dispatch }) => {
  if (!localStorage.getItem('token')) {
    commit(types.LOADING, false)
    return
  }
  commit(types.LOGIN, true)
  await dispatch('fetchUserData')
  commit(types.LOADING, false)
}
