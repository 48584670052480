import Vue from 'vue'
import Axios from 'axios'
import router from '@/router'
import store from '@/store'
import {
  displayErrors
} from '@/utils/helpers'

Axios.defaults.baseURL = process.env.VUE_APP_BASE + 'api/'

Axios.interceptors.request.use(config => {
  store.dispatch('loading')
  if (localStorage.getItem('token')) {
    config.headers.common.Authorization =
      'Bearer ' + localStorage.getItem('token')
  }
  return config
})

Axios.interceptors.response.use(
  response => {
    store.dispatch('loaded')
    return response
  },
  error => {
    store.dispatch('loaded')
    if (error.response) {
      if (error.response.status === 400) {
        // displayErrors(error)
        if (error.response.data.shouldBeShown) {
          displayErrors('Błąd! ' + error.response.data.title)
        }
      }
      if (error.response.status === 401) {
        store.dispatch('logout')
      }
      if (error.response.status === 403) {
        if (this.$store.getters.isLoggedIn) {
          router.push({
            path: '/forbidden'
          })
        }
      }
      if (error.response.status === 404) {
        router.push({
          path: '/notfound'
        })
      }
      if (error.response.status === 413) {
        displayErrors('Błąd! Za duży rozmiar plików.')
      }
    } else {
      console.log(error)
    }
    return Promise.reject(error)
  }
)

Vue.$http = Axios

Object.defineProperty(Vue.prototype, '$http', {
  get () {
    return Axios
  }
})

export default {
  async get (endpoint, config = {
    snack: true
  }) {
    return await Vue.$http.get(`${endpoint}`)
  },
  async getById (endpoint, id) {
    return await Vue.$http.get(`${endpoint}/${id}`)
  },
  async put (endpoint, data = {}) {
    return await Vue.$http.put(`${endpoint}`, data)
  },
  async post (endpoint, data = {}) {
    return await Vue.$http.post(`${endpoint}`, data)
  },
  async delete (endpoint, id) {
    return await Vue.$http.delete(`${endpoint}/${id}`)
  },
  // getFile (endpoint, id) {
  //   return Vue.$http.get(`${endpoint}/${id}`, {
  //     responseType: 'blob'
  //   })
  // },
  getFile (endpoint) {
    return Vue.$http.get(`${endpoint}`, {
      responseType: 'blob'
    })
  },
  postFile (endpoint, type, id, data) {
    return Vue.$http.post(`${endpoint}/${type}/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}
