export const loading = (state) => {
  return state.loading
}

export const isLoggedIn = (state) => {
  return state.isLoggedIn
}

export const userRoleId = state => {
  return state.userCommonData.role
}

export const currentUserId = state => {
  return state.userCommonData.id
}
