import Vue from 'vue'

/* filters */
import dataFormat from '@/filters/dateFormat'
import currency from '@/filters/currency'
import enums from '@/filters/enums'
import trimText from '@/filters/trimText'

Vue.filter('dateFormat', dataFormat)
Vue.filter('currency', currency)
Vue.filter('getDescription', enums)
Vue.filter('trimText', trimText)
