<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
        <v-text-field
            v-model="dateString"
            :label="labelText"
            prepend-icon="mdi-calendar"

            v-bind="attrs"
            v-on="on"
            :rules=rules
        ></v-text-field>
        </template>

        <v-date-picker
        @input="submit"
        v-model="date"
        no-title
        scrollable
        :min="minDate"
        >
        </v-date-picker>
    </v-menu>
</template>

<script>
export default {
  props: {
    rules: null,
    labelText: {
      typeof: String,
      default: null
    },
    value: {
      type: String,
      required: false
    },
    minDate: null
  },
  data: () => ({
    menu: false,
    time: '07:30:00'
  }),
  computed: {
    date: {
      get () {
        return this.parseDate(this.value)
      },
      set (value) {
        if (this.value.includes('T')) {
          this.time = this.value.split('T')[1]
        }

        if (value) { this.$emit('input', value.split('T')[0] + 'T' + this.time) }
      }
    },
    dateString: {
      get () {
        return this.parseDate(this.value)?.split('T')[0]
      }
    }
  },
  methods: {
    toIsoString (stringDate) {
      var date = new Date(stringDate)
      var tzo = -date.getTimezoneOffset()
      var dif = tzo >= 0 ? '+' : '-'
      var pad = function (num) {
        return (num < 10 ? '0' : '') + num
      }

      return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(Math.floor(Math.abs(tzo) / 60)) +
      ':' + pad(Math.abs(tzo) % 60)
    },
    submit (val) {
      this.$refs.menu.save(val)
    },
    parseDate (date) {
      if (date) {
        return this.toIsoString(date)
      } else {
        return null
      }
    }
  }
}
</script>
