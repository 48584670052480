<template>
  <div class="mb-6">
    <v-app-bar color="primary" height="60" app flat clipped-left dark>
      <v-spacer></v-spacer>
      <v-btn @click="openShakeGameReport" class="mr-3 green">
        <span class="hidden-xs-only">Raport Nagrodomat</span>
        <v-icon class="ml-1">mdi-file-excel-outline</v-icon>
      </v-btn>
      <v-btn @click="download" class="mr-10 green">
        Pobierz raport
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-btn icon @click="logout">
        <v-icon>mdi-login-variant</v-icon>
      </v-btn>
      <!-- <v-btn depressed class="ml-auto" @click="logout"> Wyloguj się </v-btn> -->
    </v-app-bar>
    <download-shake-game-report-dialog
      ref="downloadShakeGameReportDialog"
    ></download-shake-game-report-dialog>
  </div>
</template>

<script>
import DownloadShakeGameReportDialog from '@/components/Dialogs/DownloadShakeGameReportDialog.vue'
export default {
  name: 'AppBar',
  components: {
    DownloadShakeGameReportDialog
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
    },
    async download () {
      const result = await this.$http.get('/Report/Get', { responseType: 'arraybuffer' })
      var blob = new Blob([result.data], { type: 'application/vnd.ms-excel' })
      const fileUrl = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = fileUrl
      link.download = 'Raport wyświetleń.xlsx'
      link.click()
      URL.revokeObjectURL(link.href)
    },
    openShakeGameReport () {
      this.$refs.downloadShakeGameReportDialog.openDialog()
    }
  }
}
</script>
