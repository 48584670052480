<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Raport nagrodomatu
        </v-card-title>
        <v-card-text>
          Wybierz zakres dat
        </v-card-text>
        <v-form ref="shakeGameReportForm" flat class="mt-n3">
          <v-col cols="9">
            <DatePickerInput
              v-model="from"
              labelText='Data od'
              :rules="rules.required">
            </DatePickerInput>
          </v-col>
          <v-col cols="9">
            <DatePickerInput
              v-model="to"
              labelText='Data do'
              :rules="rules.required">
            </DatePickerInput>
          </v-col>
        </v-form>
        <v-card-actions class="mt-n3">
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            text
            @click="dialog = false"
          >
            Anuluj
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="submit"
          >
            Pobierz
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <spinner-dialog ref="SpinnerDialog"></spinner-dialog>
  </v-row>
</template>

<script>
import { displayInfo, displayErrors } from '@/utils/helpers'
import SpinnerDialog from '@/views/Spinner/SpinnerDialog'
import DatePickerInput from '@/components/DatePickerInput.vue'

export default {
  props: {
    successActionText: {
      type: String,
      default: 'Raport wygenerowany'
    }
  },
  name: 'download-shake-game-report-dialog',
  components: {
    DatePickerInput,
    SpinnerDialog
  },
  data () {
    return {
      rules: {
        required: [v => !!v || 'Pole wymagane']
      },
      dialog: false,
      from: '',
      to: ''
    }
  },
  methods: {
    async submit () {
      this.$refs.SpinnerDialog.dialog = true
      this.$refs.shakeGameReportForm.validate()
      if (!this.$refs.shakeGameReportForm.validate()) {
        displayErrors('Uzupełnij wymagane pola lub popraw błędne wartości')
        this.$refs.SpinnerDialog.dialog = false
        return
      }
      try {
        const result = await this.$http.post('Report/GenerateShakeGameReport', {
          from: this.from,
          to: this.to
        }, { responseType: 'arraybuffer' })
        if (result.status === 200) {
          this.$emit('submit')
          this.dialog = false
          displayInfo(this.successActionText)
          const blob = new Blob([result.data], { type: 'application/vnd.ms-excel' })
          const fileUrl = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = fileUrl
          link.download = 'Raport nagrodomat.xlsx'
          link.click()
          URL.revokeObjectURL(link.href)
        }
      } catch (ex) {
        console.log(ex)
        displayErrors('Wystąpił błąd.')
      }
      this.$refs.SpinnerDialog.dialog = false
    },
    openDialog () {
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
    }
  }
}
</script>
