import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import pl from 'vuetify/lib/locale/pl'
import en from 'vuetify/lib/locale/en'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#13212b',
        secondary: '#1e90df',
        success: '#34C759',
        accent: '#ff3624',
        error: '#b71c1c'
      }
    }
  },
  lang: {
    locales: {
      pl,
      en
    },
    current: 'pl'
  }
})
