export default function (value, currency = 'PLN') {
  if (!value) {
    return ''
  }

  const lang = localStorage.getItem('lang')

  return new Intl.NumberFormat(lang, {
    style: 'currency',
    currency: currency
  }).format(Number(value))
}
