import * as types from './mutation-types'
import router from '@/router/index'

export default {
  [types.LOADING] (state) {
    state.loading = true
  },
  [types.LOADED] (state) {
    state.loading = false
  },
  [types.LOGIN] (state) {
    state.isLoggedIn = true
  },
  [types.LOGOUT] (state) {
    state.isLoggedIn = false
    localStorage.removeItem('token')
    state.userCommonData.role = ''
    router.push({
      path: '/login'
    })
  },
  [types.SET_USER_COMMON_DATA] (state, data) {
    state.userCommonData = data
  }
}
