<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>

          <v-progress-circular class="loader-container"
          :size="160"
          :width="8"
          color="#436074"
          indeterminate
          ></v-progress-circular>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'spinner-dialog',
  data: () => ({
    dialog: false
  })
}
</script>

<style scoped>
  .loader-container{
    margin-left: 35%;
    margin-top: 100px;
    margin-bottom: 100px;
    padding-top: 100px;
    padding-bottom: 100px;
    display: block;
  }
</style>
